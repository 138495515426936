<template>
  <CCard>
    <CCardHeader>
      <strong>{{ titulo }}</strong>
    </CCardHeader>
    <CCardBody>
      <CForm :items="premio">
        <CInput
          id="title"
          description="Título del premio"
          label="Título del premio"
          horizontal
          :value="premio.award_title"
          :disabled="informacion"
        />
        <CInput
          id="descripcion"
          description="Descricpción del premio"
          label="Descripción del premio"
          horizontal
          :value="premio.award_description"
          :disabled="informacion"
        />
        <CInputFile
          v-if="!informacion"
          id="imagen"
          accept="image/*"
          label="Insertar imagen del premio"
          horizontal
          :description="premio.award_image"
          @change="showUploadedImage($event)"
        />
        <div
          v-if="!informacion"
          class="d-flex justify-content-center"
        >
          <img
            ref="uploadedImage"
            height="150px"
          >
        </div>

        <div
          v-if="informacion"
          class="d-flex justify-content-center"
        >
          <img
            :src="premio.award_image"
            height="150px"
          >
        </div>
      </CForm>
      <div class="d-flex justify-content-center">
        <CButton
          class="btn btn-secondary"
          @click="volver()"
        >
          Cancelar
        </CButton>
        <CButton
          v-if="!informacion"
          class="btn btn-success"
          @click="editButton()"
        >
          {{ textBoton }}
        </CButton>
      </div>
    </CCardBody>
    <CRow class="justify-content-center">
      <CCol
        md="9"
        class="p-4"
      >
        <CAlert
          color="danger"
          dismissible
          :show.sync="showPayloadError"
          close-button
        >
          <strong>AVISO</strong> El archivo a subir es demasiado pesado

          <CProgress
            :max="10"
            :value="showPayloadError"
            height="3px"
            color="danger"
            animated
          />
        </CAlert>
        <CAlert
          color="danger"
          dismissible
          :show.sync="showParametersError"
          close-button
        >
          <strong>AVISO</strong> Campos vacios, erroneos o imagen muy grande (Max 2MB)

          <CProgress
            :max="10"
            :value="showParametersError"
            height="3px"
            color="danger"
            animated
          />
        </CAlert>
      </CCol>
    </CRow>
  </CCard>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from "jquery";

/**
 * Función para conseguir los datos de un premio dado por la id.
 * 
 * @returns {Promise<object>} Promesa que al resolverse nos da los datos del premio.
 */
async function getPremioData(id) {
  let data;
  const token = localStorage.token;
  await axios.get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_PREMIOS + id, {
      headers: {
        Authorization: token,
      },
    }).then((result) => {
      data = result.data;
    }).catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/404";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
  return data;
}

export default {
  name: "EditarPremio",
  data() {
    return {
      premio: {},
      temp: 0,
      tempC: 0,
      categorias: [],
      id: null,
      titulo: "Nuevo premio",
      textBoton: "Crear premio",
      informacion: false,
      showPayloadError: 0,
      showParametersError: 0,
    };
  },
  created() {
    //Aquí llamamos a los metodos para que se llamen a las funciones y recoger los datos
    //También dependiendo de si uno de los parametros que se pasa por la url, hacemos que sea para editar o de información
    this.id = this.$route.params.id;
    if (this.$route.params.nombre == "infoPremio") {
      this.informacion = true;
    } else {
      this.informacion = false;
    }
    this.recibirDatos();
  },
  methods: {
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla
     */
    recibirDatos() {
      if (!this.id)
        return;

      this.titulo = "Editar premio";
      this.textBoton = "Editar";

      if (this.temp > 0) {
        return this.premio;
      }
      let data = getPremioData(this.id);
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.premio = result;
        this.premio.award_image =
          process.env.VUE_APP_URL_IP_PUBLIC + this.premio.award_image;
      });
      this.temp++;
    },
    volver() {
      //el botón para volver hacia atrás
      this.$router.push("/premio/");
    },
    /**
     * Función asociada al boton que realizara una u otra acción
     * dependiendo si se va a editar o crear un premio.
     */
    editButton() {
      let titulo = $("#title").val();
      let descripcion = $("#descripcion").val();
      let imagen = $("#imagen").prop("files");
      let token = localStorage.token;
      let image = imagen.item(0);
      let formData = new FormData();

      formData.append("award_title", titulo);
      formData.append("award_description", descripcion);
      formData.append("award_image", image);

      if (!this.id) {
        if(this.checkImage(image) && titulo.length && descripcion.length) {
          axios.post(process.env.VUE_APP_URL + process.env.VUE_APP_URL_PREMIOS + "create",
              formData,
              {
                headers: {
                  Authorization: token,
                },
              }
            ).then(() => {
              this.$router.push("/premio");
            }).catch((error) => {
              if (error.response) {
                if (error.response.status == 400) {
                  this.mostrarFalloParametros();
                }
                if (error.response.status == 401) {
                  window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                  window.location = "#/404";
                }
                if (error.response.status == 413) {
                  this.mostrarFalloPayload();
                }
                if (error.response.status == 500) {
                  window.location = "#/500";
                }
              }
            });
        } else {
          this.mostrarFalloParametros();
        }

        return;
      }

      if(this.checkImage(image) || image == null) {
        axios.patch(process.env.VUE_APP_URL + process.env.VUE_APP_URL_PREMIOS + this.id,
            formData,
            {
              headers: {
                Authorization: token,
              },
            }
          ).then(() => {
            this.$router.push("/premio");
          }).catch((error) => {
            if (error.response) {
              if (error.response.status == 400) {
                this.mostrarFalloParametros();
              }
              if (error.response.status == 401) {
                window.location = "#/pages/login";
              }
              if (error.response.status == 404) {
                window.location = "#/404";
              }
              if (error.response.status == 413) {
                this.mostrarFalloPayload();
              }
              if (error.response.status == 500) {
                window.location = "#/500";
              }
            }
          });
      } else {
        this.mostrarFalloParametros();
      }
    },
    mostrarFalloPayload() {
      this.showPayloadError = 10;
    },
    mostrarFalloParametros() {
      this.showParametersError = 10;
    },
    /**
     * Función para comprobar la extension y tamaño de una imagen
     */
    checkImage(image) {
      return(image && image.size <= parseInt(process.env.VUE_APP_MAX_IMAGE_FILE_SIZE) && process.env.VUE_APP_ALLOWED_IMAGE_MIMES.split(';').includes(image.type));
    },
    showUploadedImage(event) {
      if(event[0]) this.$refs.uploadedImage.src = URL.createObjectURL(event[0]);
    }
  },
};
</script>